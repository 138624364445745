import { ref, toValue, toRaw } from 'vue'
import { useAuthStore } from '@/stores'
import { useRouter } from 'vue-router'

const baseUri = process.env.VUE_APP_API_URL

export function useBackend(endpoint, path) {
    const item = ref(null)
    const items = ref([])
    const error = ref(null)
    const totalItems = ref(0)
    const loading = ref(true)

    const router = useRouter()

    const getItems = async (params = {}) => {
        
        error.value = null

        // Resolve refs to their raw values
        const resolvedParams = Object.keys(params).reduce((acc, key) => {
            acc[key] = toValue(params[key]);
            return acc;
        }, {});
        
        // Construct query string from params object
        const queryString = new URLSearchParams(resolvedParams).toString();
        const urlWithParams = `${baseUri}/${endpoint}?${queryString}`;

        const response = await customFetch(urlWithParams, {
            method: 'GET',
        });
        
        if (response) {
            items.value = response.data || response
            totalItems.value = response.totalRecords || items.value.length
        }
    
        loading.value = false
    }

    const getItem = async(id, params = {}) => {

        loading.value = true
        item.value = null
        error.value = null

        // Resolve refs to their raw values
        const resolvedParams = Object.keys(params).reduce((acc, key) => {
            acc[key] = toValue(params[key]);
            return acc;
        }, {});
        
        // Construct query string from params object
        const queryString = new URLSearchParams(resolvedParams).toString();
        const urlWithParams = `${baseUri}/${endpoint}/${id}?${queryString}`;
        
        const response = await customFetch( urlWithParams, {
            method: 'GET',
        });
        
        if (response) {
            item.value = response
        }

        loading.value = false
    }

    const downloadItem = async(id, params = {}) => {
        error.value = null

        const resolvedParams = Object.keys(params).reduce((acc, key) => {
            acc[key] = toValue(params[key]);
            return acc;
        }, {});
        
        const queryString = new URLSearchParams(resolvedParams).toString();
        const urlWithParams = `${baseUri}/${endpoint}/${id}?${queryString}`;

        const response = await customFetch(urlWithParams, { method: 'GET', responseType: 'blob' });

        if (response) {
            const blob = new Blob([response], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            const contentDisposition = response.headers.get('content-disposition');
            let fileName = 'downloaded-file';
            
            if (contentDisposition) {
                const match = contentDisposition.match(/filename\*=UTF-8''([^;]+)|filename="([^"]+)"/);
                if (match) {
                    fileName = decodeURIComponent(match[1] || match[2]);
                }
            }

            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }
    }

    const addItem = async (data) => {
        
        error.value = null

        const response = await customFetch( `${baseUri}/${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        } )
        
        if (response) {
            item.value = response
            if( path )
            {
                router.push( `${path}/${item.value.id}`)
            }
            
        }
    }

    const addItemWithFile = async (data, file) => {
        error.value = null;
        const formData = new FormData();
    
        // Append files correctly
        if (Array.isArray(file)) {
            file.forEach(f => {
                formData.append("files", f);  // 'files' is the key expected by the backend
            });
        } else if (file) {
            formData.append("file", file);
        }
    
        // Append other data correctly, especially arrays
        for (const key in data) {
            if (Array.isArray(data[key])) {
                data[key].forEach(value => {
                    formData.append(`${key}`, value);
                });
            } else {
                formData.append(key, data[key]);
            }
        }
    
        const response = await customFetch(`${baseUri}/${endpoint}`, {
            method: 'POST',
            body: formData,
        });
    
        if (response) {
            item.value = response;
            if (path) {
                router.push(`${path}/${item.value.id}`);
            }
        }
    }

    const updateItem = async (id) => {
        
        error.value = null

        const rawData = toRaw(item.value);
        console.log(rawData);

        const response = await customFetch( `${baseUri}/${endpoint}/${id}`, { 
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(rawData) 
        } )
        
        if (response) {
            item.value = response
            if( path )
            {
                router.push( `${path}/${item.value.id}`)
            }
        }
    }

    const deleteItem = async (id) => {
        error.value = null
        console.log( "ItemId To Delete:", id)
        const response = await customFetch( `${baseUri}/${endpoint}/${id}`, { method: 'DELETE' } )
        
        if (response) {
            if( path )
            {
                router.push( `${path}/${item.value.id}`)
            }
        }

    }

    async function customFetch(url, options = {}, retry = true) {
        const { auth, isLoggedIn, refreshToken } = useAuthStore();
    
        if (isLoggedIn) {
            const expirationDate = new Date(auth.expiration);
            const now = new Date();
    
            if (now >= expirationDate) {
                await refreshToken(); // Refresh the token
            }
    
            // Merge the Authorization header with any existing headers
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${auth.accessToken}`,
            };
        }
    
        try {
            const response = await fetch(url, options);
            console.log(response);
            if (!response.ok) {
                if (response.status == 401 && retry) {
                    await refreshToken(); // Refresh the token
                    return await customFetch(url, options, false);
                } else {
                    // Try to parse the error response body
                    let errorData = {};
                    try {
                        errorData = await response.json();
                    } catch (e) {
                        // If parsing fails, use the status text
                        errorData = { message: response.statusText || "An error occurred" };
                    }
    
                    error.value = {
                        status: response.status,
                        message: errorData.message || "An error occurred",
                        errors: errorData.errors || null,
                    };
    
                    // If unauthorized, clear auth
                    if (response.status === 401) {
                        auth.value = null;
                    }
                }
            } else {
                if (options.responseType === 'blob') {
                    const blob = await response.blob();
                    blob.headers = response.headers;
                    return blob;
                } else {
                    return await response.json();
                }
            }
        } catch (err) {
            console.log(err);
            error.value = { status: null, message: "An unknown error occurred" };
        }
    }

    return {
        item,
        items,
        totalItems,
        error,
        downloadItem,
        getItems,
        getItem,
        addItem,
        addItemWithFile,
        updateItem,
        deleteItem,
        loading
    }
}